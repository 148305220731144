<template lang="pug">
  v-app(id="invention-studio")
    div
      div(v-if="configLoadingError")
        critical-error(errorMsg="The service is currently unavailable. Please check back shortly. If the problem persists, please contact our Technical Support team.")
      Login(v-else-if="!hasUser")
      template(v-else)

        v-navigation-drawer(app :mini-variant="mainMenuCollapsed" mobile-breakpoint=0 floating dark color="iprovaDark" )
          v-list.px-0.d-flex.flex-column.full-height(dense nav)
            RouterLink.d-contents(:to="{ name: 'dashboard' }")
              v-list-item.icon-menu.mt-n1
                v-list-item-icon
                  span.iprova-profile-icon.ml-n2.mr-n4.mt-n1(:style="{ backgroundImage: `url('${this.profile.profile_picture}')` }" v-if="profile.profile_picture")
                  span.iprova-icon.ml-n2.mr-n4.mt-n1(v-else)
                v-list-item-content
                  v-list-item-title Invention Studio
                  v-list-item-subtitle v7.2
            v-list-item(:to="{ name: 'dashboard' }")
              v-tooltip(right nudge-right="10px") Dashboard
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-monitor-dashboard
              v-list-item-content
                v-list-item-title Dashboard
            v-list-item(v-if="STANDARDS_ACCESS" :to="{ name: 'standards'}")
              v-tooltip(right nudge-right="10px") Explore Standards
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-receipt-text-check-outline
              v-list-item-content
                v-list-item-title Explore Standards
            v-list-item(v-if="STANDARDS_ACCESS" :to="{ name: 'track-inventions' }")
              v-tooltip(right nudge-right="10px") Track Inventions
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-target
              v-list-item-content
                v-list-item-title Track Inventions
            v-divider
            v-list-item(href="https://iprova-jira.atlassian.net/servicedesk/customer/portal/2" target="_blank" tile)
              v-tooltip(right nudge-right="10px") Give Feedback
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-comment-quote-outline
              v-list-item-content
                v-list-item-title Give Feedback
            v-list-item(href="https://iprova-jira.atlassian.net/servicedesk/customer/portal/2/article/2281373697" target="_blank" tile)
              v-tooltip(right nudge-right="10px") User Guide
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-help-circle-outline
              v-list-item-content
                v-list-item-title User Guide
            v-divider
            v-spacer
            v-list-item
              v-tooltip(right nudge-right="20px") Dark Mode
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-action.my-1.me-4(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    div.theme-mode-switch
                      v-switch(color="#1DAFED" inset v-model="darkMode" dense id="darkModeSwitch")
                      span.icon(
                        :class="darkMode ? 'dark' : 'light'"
                        @click="darkMode = !darkMode"
                      )
              v-list-item-content
                v-list-item-title
                  label(for="darkModeSwitch") Dark Mode
            v-list-item(:to="{ name: 'profile-settings' }")
              v-tooltip(right nudge-right="10px") Settings
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-cog-outline
              v-list-item-content
                v-list-item-title Settings
            v-list-item(@click="logout()" tile)
              v-tooltip(right nudge-right="10px") Logout
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon.ms-2(v-bind="mainMenuCollapsed && attrs" v-on="mainMenuCollapsed && on")
                    v-icon mdi-logout
              v-list-item-content
                v-list-item-title Logout

        v-main(:class="[currentTheme, $router.currentRoute.name]")
          div.d-flex.align-center.header.header-wrapper.pt-5.pb-3.pr-6
            div.d-flex.align-center
              span.text-h4.text-no-wrap
                v-icon.ml-3.mr-1.mb-1.pa-3.menu-collapse(
                  size="24"
                  color="primary"
                  @click="mainMenuCollapsed = !mainMenuCollapsed"
                ) {{ mainMenuCollapsed ? 'mdi-menu' : 'mdi-close' }}
                span.ml-3 {{ $route.meta.title }}

            v-spacer

            // Centered Icon
            div.iprova-dashboard-icon(:class="currentTheme")

            v-spacer

            div.d-flex.align-center
              slot(name="search")

              v-btn(
                icon
                outlined
                v-show="hasHelpsToShow"
                :disabled="!hasDismissedHelps"
                @click="showHelps()"
                small
              )
                v-icon(small) mdi-help
          slot(name="default")

        canvas-popover(
          v-if="currentProject"
          v-show="showPopover"
          :isShown="showPopover"
        )

        main-snackbar
</template>

<script>
import MainSnackbar from '@/components/MainSnackbar.vue'
import { usePreferenceSyncedRef } from '@/utils/preferencesManager'
import Login from '@/modules/auth/views/Login'
import { loadLanguageAsync } from '@/plugins/i18n'
import { authStore } from '@/store/modules/auth'
import { projectsStore } from '@/modules/projects/store'
import { appStore } from '@/store/modules/app'
import { mapState } from 'pinia'
import CanvasPopover from '../modules/canvas/views/CanvasPopover'
import { useDismissibleHelpStore } from '@/components/store/dismissibleHelpStore'
import { useFlagsStore } from '@/store/modules/flags'
import { useConfigStore } from '@/store/modules/configuration'
import CriticalError from '@/components/CriticalError.vue'
import { onMounted, ref } from 'vue'

export default {
  components: {
    CriticalError,
    MainSnackbar,
    Login,
    CanvasPopover
  },
  inject: ['currentTheme'],
  setup() {
    const today = ref(new Date())
    const mainMenuCollapsed = usePreferenceSyncedRef(false, 'MAIN_MENU_COLLAPSED', {})
    const darkMode = usePreferenceSyncedRef(false, 'CURRENT_THEME', {})

    const updateToday = () => {
      today.value = new Date()
    }

    onMounted(async function() {
      await Promise.all([
        loadLanguageAsync(),
        authStore().fetchProfile()
      ])
      setTimeout(() => {
        updateToday()
        setInterval(updateToday, 24 * 3600 * 1000)
      }, new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 1) - this.today)
      this.setupLeaveGuard()
    })

    return { mainMenuCollapsed, darkMode, today }
  },

  computed: {
    ...mapState(authStore, ['displayName', 'profile']),
    ...mapState(projectsStore, ['currentProject', 'currentProjectId']),
    ...mapState(useFlagsStore, ['INVENTION_TRACKER_ENABLED', 'OPPORTUNITY_ATLAS_ENABLED', 'STANDARDS_ACCESS']),
    hasUser() {
      return Boolean(this.displayName)
    },
    configLoadingError() {
      return Boolean(useConfigStore().error)
    },
    viewTitle() {
      try {
        return this.$route.matched[0].instances?.default?.viewTitle || ''
      } catch (e) {
        // ignore
        return ''
      }
    },
    showPopover() {
      return Boolean(this.currentProject && this.$route.meta.hideCanvasPopover !== true)
    },
    version() {
      return this.OPPORTUNITY_ATLAS_ENABLED ? 'v7.0' : 'v6.6'
    },
    hasHelpsToShow() {
      return useDismissibleHelpStore().availableHelp.length > 0
    },
    hasDismissedHelps() {
      return useDismissibleHelpStore().dismissedHelp.length > 0
    },
    legacyLink() {
      return this.currentProjectId
        ? { name: 'legacy-project', params: { project_id: this.currentProjectId } }
        : { name: 'legacy-dashboard' }
    }
  },

  watch: {
    async hasUser(newValue) {
      if (newValue) {
        await projectsStore().getCurrent()
        if (this.$route.name === 'login') {
          const afterLogin = localStorage.getItem('afterLogin') || { name: 'home' }
          localStorage.removeItem('afterLogin')
          await this.$router.replace(afterLogin)
        }
      }
    },
    async $route(newRoute, oldRoute) {
      const oldProjectId = oldRoute?.params?.project_id
      const newProjectId = newRoute?.params?.project_id
      if (oldProjectId === newProjectId) return
      if (newProjectId === undefined) projectsStore().currentProject = null
      else await projectsStore().fetchCurrent(newProjectId)
    },
    viewTitle(newValue) {
      window.document.title = newValue || 'Invention Studio'
    },
    darkMode(value) {
      this.$vuetify.theme.isDark = value
      this.$vuetify.theme.applyTheme()
    }
  },
  methods: {
    showHelps() {
      useDismissibleHelpStore().clearDismissed()
    },
    async logout() {
      await authStore().logout()
      await this.$router.push({ name: 'login' })
    },
    setupLeaveGuard() {
      // Required to handle save changes confirmation on browser page reload.
      // The message specified bellow will be shown on older browsers,
      // new browsers will display their own generic message which cannot be overwritten
      window.addEventListener('beforeunload', (e) => {
        if (appStore().hasPendingChanges) {
          e.preventDefault()
          // chrome requires returnValue to be set
          const message = 'You have unsaved changes.\n\n' +
            'Are you sure you want to leave this page?'
          e.returnValue = message
          return message
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">

.full-height {
  height: 100vh;

  .v-list-item {
    flex: 0;
  }

  .v-spacer {
    flex: 1 !important;
  }

  .icon-menu {
    height: 64px !important;
    min-height: 64px;
  }
}

.iprova-icon {
  width: 60px;
  height: 60px;
  display: block;
  background-position: top left;
  background-size: contain;
  background-image: url(resources/iprova-icon-small-white.png);
}

.iprova-profile-icon {
  width: 30px;
  height: 30px;
  display: block;
  background-position: top left;
  background-size: cover;
  margin: 5px !important;
  border-radius: 50%;
}

.header-wrapper {
  position: relative;

  @media (max-width: 1200px) {
    position: unset;
  }
}

.iprova-dashboard-icon {
  width: 180px;
  height: 40px;
  display: block;
  background-position: top left;
  background-size: contain;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  &.theme--light {
    background-image: url(resources/invention-studio-evo-logo.png);
  }

  &.theme--dark {
    background-image: url(resources/invention-studio-evo-logo-dark-mode.png);
  }

  @media (max-width: 1200px) {
    position: unset;
    transform: none;
  }
}

.v-list-item {
  margin-bottom: 0 !important;

  &::before {
    border-radius: 0 !important;
  }
}

.v-card {
  overflow: auto;
}

.v-toolbar__content {
  max-width: 1845px;
  margin: 0 auto;
}

.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}

.theme--light.v-btn.elevation-0:not(.v-btn--text):not(.v-btn--outlined) {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.v-btn.elevation-0:not(.v-btn--text):not(.v-btn--outlined) {
  border: thin solid rgba(255, 255, 255, 0.12);
}

.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
  background: none;
}

.gutter.gutter {
  background-color: rgba(128, 128, 128, 0.3)
}

.v-pagination.theme--light button {
  border-radius: 0;
  border: thin solid rgba(0, 0, 0, 0.12);
  box-shadow: none;
}

.v-pagination.theme--dark button {
  border-radius: 0;
  border: thin solid rgba(255, 255, 255, 0.12);
  box-shadow: none;
}

[tile][tile][tile][tile][tile] {
  border-radius: 0;

  &::before, &::after {
    border-radius: 0;
  }
}

.v-expansion-panel::before {
  display: none;
}

.problemmining-feed {
  overflow: unset;
}

span.search-highlight {
  background-color: rgba(255, 255, 0, 0.4);
  font-weight: bolder;
  white-space: nowrap;
}

.menu-collapse {
  z-index: 1;
  position: relative;
}

.theme-mode-switch {
  position: relative;
  display: inline-flex;
  align-items: center;

  .icon {
    position: absolute;
    top: 11px;
    left: 4px;
    transform: translateY(-50%);
    transition: left 0.3s ease;
    height: 14px;
    width: 14px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .dark {
    left: 24px;
    background-image: url('resources/night.svg');
  }

  .light {
    left: 2px;
    background-image: url('resources/sun.svg');
  }
}
</style>

<style lang="scss">
.d-contents {
  display: contents;
}
</style>
