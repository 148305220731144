export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    components: {
      default: () => import(/* webpackChunkName: "dashboard" */ '@/modules/dashboard/views/DashboardView'),
      search: () => import(/* webpackChunkName: "dashboard" */ '@/modules/dashboard/views/DashboardSearch')
    }
  },
  {
    path: '/',
    name: 'dashboard',
    components: {
      default: () => import(/* webpackChunkName: "dashboard" */ '@/modules/dashboard/views/DashboardView'),
      search: () => import(/* webpackChunkName: "dashboard" */ '@/modules/dashboard/views/DashboardSearch')
    }
  }
]
