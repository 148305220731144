import { $, go } from '@/plugins/gojs';
import vtf from '@/plugins/vuetify';
import 'gojs/extensionsJSM/TextEditorSelectBox';
import store from '@/store';
import findKey from 'lodash/findKey';
import { createTrackedTrigger, deleteTrackedTrigger } from '../../../api/trackedTriggers';
import { dateToIsoDate, DocumentType, ESSENTIAL_FEATURE_COLORS, ESSENTIAL_FEATURE_TYPES } from '../../../utils/constants';
import { ensureUrlHasProtocol } from '../../../utils/strings';
import { TRACKED_TRIGGER_SOURCE } from '../../triggers/constants';
import { mapEssentialFeatures } from '../../triggers/utils/triggerTracking';
const svgIcons = {
    openInNew: 'M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z',
    deleteOutline: 'M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z',
    plusOutline: 'M19,19V5H5V19H19M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5C3,3.89 3.9,3 5,3H19M11,7H13V11H17V13H13V17H11V13H7V11H11V7',
    starIcon: 'M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z',
    closeIcon: 'M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z',
    noteIcon: 'M15 3H5A2 2 0 0 0 3 5V19A2 2 0 0 0 5 21H19A2 2 0 0 0 21 19V9L15 3M19 19H5V5H14V10H19M17 14H7V12H17M14 17H7V15H14'
};
export const showSmallPorts = function (node, show) {
    node.ports.each((port) => {
        if (port.portId !== '') { // don't change the default port, which is the big shape
            // @ts-ignore
            port.fill = show ? 'rgba(123,123,123,.5)' : null;
        }
    });
};
export const onLinkReshaped = function (e) { e.subject.routing = go.Link.Orthogonal; };
export const TRACKED_TRIGGER_PANEL = function () {
    const colorByTheme = vtf.framework.theme.isDark ? 'white' : 'black';
    const inverseColorByTheme = vtf.framework.theme.isDark ? 'black' : 'white';
    const borderColor = vtf.framework.theme.isDark ? '#6d788a' : 'rgba(128, 128, 128, 0.5)';
    const removeTrigger = (obj) => {
        const node = obj.part;
        if (node?.diagram) {
            const diagram = node.diagram;
            diagram.startTransaction('delete node');
            diagram.remove(node);
            diagram.commitTransaction('delete node');
        }
    };
    const saveTrigger = async (e, obj, payload) => {
        try {
            const createdTT = await createTrackedTrigger(payload);
            store.commit('snackbar/show', { color: 'success', message: 'Trigger successfully added to tracker.' });
            e.diagram.model.startTransaction('updateNodeTrackTrigger');
            e.diagram.model.setDataProperty(obj.part.data, 'virtual_id', createdTT.data.virtual_id);
            e.diagram.model.setDataProperty(obj.part.data, 'id', createdTT.data.id);
            e.diagram.model.commitTransaction('updateNodeTrackTrigger');
        }
        catch (e) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'Failed to save. Please contact support.'
            });
        }
    };
    const deleteTrigger = async (e, obj, id) => {
        try {
            await deleteTrackedTrigger(id);
            store.commit('snackbar/show', { color: 'success', message: 'Trigger successfully removed from tracker.' });
            e.diagram.model.startTransaction('updateNodeTrackTrigger');
            e.diagram.model.setDataProperty(obj.part.data, 'virtual_id', null);
            e.diagram.model.commitTransaction('updateNodeTrackTrigger');
        }
        catch (e) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'Failed to remove. Please contact support.'
            });
        }
    };
    const saveOrRemoveTT = (e, obj) => {
        const nodeData = obj.part?.data;
        if (!nodeData)
            return;
        if (nodeData?.virtual_id) {
            // Untrack the trigger
            deleteTrigger(e, obj, nodeData.id);
        }
        else {
            // Track the trigger
            const triggerPayload = {
                assignee: nodeData.assignee ?? '',
                patent_number: nodeData.patent_number ?? '',
                title: nodeData.title,
                content: Array.isArray(nodeData.content) ? nodeData.content.join(' ') : nodeData.content ?? '',
                url: ensureUrlHasProtocol(nodeData.url),
                trigger_date: nodeData.trigger_date ?? dateToIsoDate(new Date()),
                doc_type: nodeData.doc_type ?? DocumentType.JOURNAL,
                source: nodeData.source ?? TRACKED_TRIGGER_SOURCE.INV_CANVAS_TRIGGER_HUNTING_WIDGET,
                essential_features: mapEssentialFeatures(nodeData.essentialFeatures)?.filter((ef) => Boolean(ef.content.trim())) ?? [],
                project_id: nodeData.project_id,
                location_x: nodeData.location_x,
                location_y: nodeData.location_y,
                user_id: nodeData.user_id ?? undefined
            };
            saveTrigger(e, obj, triggerPayload);
        }
    };
    const getStarIconColor = (virtualId) => {
        if (virtualId) {
            return vtf.framework.theme.isDark ? '#1dafed' : '#172345';
        }
        return '#ffffff';
    };
    return $(go.Panel, 'Auto', { name: 'TRACKED_TRIGGER_PANEL', minSize: new go.Size(200, 30) }, new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(go.Size.stringify), $(go.Shape, 'Rectangle', {
        fill: inverseColorByTheme,
        strokeWidth: 1,
        stroke: borderColor,
        portId: '',
        fromLinkable: true,
        toLinkable: true,
        cursor: 'pointer',
        name: 'SHAPE-TRIGGER',
        figure: 'RoundedRectangle',
        parameter1: 5
    }, new go.Binding('figure')), $(go.Panel, 'Table', {
        defaultAlignment: go.Spot.TopLeft,
        margin: new go.Margin(0, 10, 0, 10)
    }, new go.Binding('desiredSize', 'size', go.Size.parse), $(go.RowColumnDefinition, { row: 1, separatorStroke: inverseColorByTheme }), 
    // track trigger
    // @ts-ignore
    $('Button', {
        row: 0,
        column: 1,
        alignment: go.Spot.TopRight,
        'ButtonBorder.figure': 'Rectangle',
        'ButtonBorder.fill': colorByTheme === 'white' ? 'black' : 'white',
        'ButtonBorder.strokeWidth': 0,
        _buttonFillOver: colorByTheme === 'white' ? 'black' : 'white',
        margin: new go.Margin(4, 30, 4, 4),
        click: (e, obj) => saveOrRemoveTT(e, obj)
    }, $(go.Shape, {
        margin: new go.Margin(-2, 1, 1.55, -2),
        geometry: go.Geometry.parse(svgIcons.starIcon, true)
    }, new go.Binding('fill', '', (nodeData) => getStarIconColor(nodeData?.virtual_id)), new go.Binding('strokeWidth', '', (nodeData) => nodeData?.virtual_id ? 0 : 1), {
        toolTip: $('ToolTip', { 'Border.strokeWidth': 0, 'Border.fill': 'rgba(97, 97, 97, 0.9)' }, $(go.TextBlock, { margin: 8, stroke: 'white', font: '14px IBM Plex Sans' }, new go.Binding('text', '', (nodeData) => nodeData?.virtual_id ? 'Untrack trigger' : 'Track trigger')))
    })), 
    // remove trigger node button
    // @ts-ignore
    $('Button', {
        row: 0,
        column: 1,
        alignment: go.Spot.TopRight,
        'ButtonBorder.figure': 'Rectangle',
        'ButtonBorder.fill': colorByTheme === 'white' ? 'black' : 'white',
        'ButtonBorder.strokeWidth': 0,
        _buttonFillOver: colorByTheme === 'white' ? 'black' : 'white',
        margin: new go.Margin(4, 2, 4, 4),
        click: (e, obj) => removeTrigger(obj)
    }, $(go.Shape, {
        margin: new go.Margin(-2, 1, 1.55, -2),
        fill: colorByTheme !== 'white' ? 'rgba(0, 0, 0, 0.54)' : '#ffffff',
        strokeWidth: 0,
        geometry: go.Geometry.parse(svgIcons.closeIcon, true)
    }, {
        toolTip: $('ToolTip', { 'Border.strokeWidth': 0, 'Border.fill': 'rgba(97, 97, 97, 0.9)' }, $(go.TextBlock, { margin: 8, stroke: 'white', font: '14px IBM Plex Sans' }, 'Remove Trigger'))
    })), 
    // open url in new tab button
    // @ts-ignore
    $('Button', {
        row: 0,
        column: 1,
        alignment: go.Spot.TopLeft,
        'ButtonBorder.figure': 'Rectangle',
        'ButtonBorder.fill': colorByTheme === 'white' ? 'black' : 'white',
        'ButtonBorder.strokeWidth': 0,
        _buttonFillOver: colorByTheme === 'white' ? 'black' : 'white',
        margin: new go.Margin(5, 4, 4, 10),
        click: (e, obj) => obj.part && window.open(obj.part.data.url, '_blank')
    }, $(go.Shape, {
        margin: new go.Margin(-2, 1, 1.55, -2),
        fill: colorByTheme !== 'white' ? 'rgba(0, 0, 0, 0.54)' : '#ffffff',
        strokeWidth: 0,
        geometry: go.Geometry.parse(svgIcons.noteIcon, true)
    }, {
        toolTip: $('ToolTip', { 'Border.strokeWidth': 0, 'Border.fill': 'rgba(97, 97, 97, 0.9)' }, $(go.TextBlock, { margin: 8, stroke: 'white', font: '14px IBM Plex Sans' }, new go.Binding('text', 'url')))
    })), 
    // trigger title
    $(go.Panel, 'Auto', 
    // @ts-ignore
    {
        row: 0,
        column: 1,
        margin: new go.Margin(10, 75, 5, 40),
        maxSize: new go.Size(550, NaN),
        click: (e, obj) => obj.part && window.open(obj.part.data.url, '_blank')
    }, $(go.TextBlock, {
        stroke: colorByTheme,
        font: 'bold 16px IBM Plex Sans, sans-serif',
        cursor: 'pointer',
        editable: false
    }, new go.Binding('maxSize', 'size', go.Size.parse), new go.Binding('text', 'title').makeTwoWay())), 
    //  essential features table
    $(go.Panel, 'Table', new go.Binding('itemArray', 'essentialFeatures'), new go.Binding('maxSize', 'size', go.Size.parse), {
        name: 'ESSENTIAL_FEATURES',
        row: 2,
        columnSpan: 2,
        defaultAlignment: go.Spot.Left,
        defaultRowSeparatorStrokeWidth: 1,
        defaultRowSeparatorStroke: 'rgba(190, 190, 190, 0.5)',
        itemTemplate: // bound to a essentialFeature/row data object
        $(go.Panel, 'TableRow', 
        // essential feature type choices
        $(go.Panel, 'Auto', {
            alignment: go.Spot.Top,
            desiredSize: new go.Size(87, 24),
            margin: new go.Margin(20, 20, 5, 0)
        }, $(go.Shape, 'RoundedRectangle', { strokeWidth: 0, parameter1: 20, desiredSize: new go.Size(87, 24) }, new go.Binding('fill', 'type', (type) => ESSENTIAL_FEATURE_COLORS[type])), $(go.TextBlock, {
            margin: new go.Margin(4, 4, 4, 4),
            textAlign: 'center',
            verticalAlignment: go.Spot.Center,
            font: '14px IBM Plex Sans, sans-serif',
            stroke: 'white',
            cursor: 'pointer',
            editable: true,
            choices: Object.values(ESSENTIAL_FEATURE_TYPES),
            // @ts-ignore
            textEditor: window.TextEditorSelectBox
        }, new go.Binding('text', 'type', (type) => ESSENTIAL_FEATURE_TYPES[type])
            .makeTwoWay((value) => findKey(ESSENTIAL_FEATURE_TYPES, type => type === value)), new go.Binding('background', 'type', (type) => ESSENTIAL_FEATURE_COLORS[type]))), 
        // Delete essential feature button
        // @ts-ignore
        $('Button', {
            background: inverseColorByTheme,
            _buttonFillOver: 'none',
            'ButtonBorder.fill': 'none',
            'ButtonBorder.strokeWidth': 0,
            alignment: go.Spot.TopRight,
            desiredSize: new go.Size(15, 24),
            margin: new go.Margin(20, 10, 5, 115),
            click: (e, obj) => {
                if (!obj.panel || !obj.part?.isSelected)
                    return;
                const feature = obj.panel.data;
                const trigger = obj.part.data;
                e.diagram.startTransaction('delete essential feature');
                e.diagram.model.setDataProperty(trigger, 'essentialFeatures', trigger.essentialFeatures.filter((ef) => ef.type !== feature.type || ef.content !== feature.content));
                e.diagram.commitTransaction('delete essential feature');
            }
        }, $(go.Shape, {
            margin: new go.Margin(-2, 1, 1, -3),
            fill: '#f08181',
            strokeWidth: 0,
            desiredSize: new go.Size(15, 15),
            geometry: go.Geometry.parse(svgIcons.deleteOutline, true)
        })), 
        // essential feature content
        $(go.Panel, 'Auto', {
            column: 1,
            margin: new go.Margin(20, 0, 5, 0),
            maxSize: new go.Size(500, NaN)
        }, $(go.TextBlock, {
            stroke: colorByTheme,
            font: '16px IBM Plex Sans, sans-serif',
            cursor: 'move',
            editable: true
        }, new go.Binding('text', 'content').makeTwoWay())))
    }), 
    // add essential feature button
    // @ts-ignore
    $('Button', {
        row: 2,
        column: 1,
        background: inverseColorByTheme,
        _buttonFillOver: 'none',
        'ButtonBorder.fill': 'none',
        'ButtonBorder.strokeWidth': 0,
        alignment: go.Spot.TopRight,
        margin: new go.Margin(2, 2),
        click: (e, obj) => {
            if (!obj.part)
                return;
            const trigger = obj.part.data;
            const essentialFeatures = [
                ...(trigger.essentialFeatures || []),
                { type: 'ABILITY', content: '' }
            ];
            e.diagram.startTransaction('add essential feature');
            e.diagram.model.setDataProperty(trigger, 'essentialFeatures', essentialFeatures);
            e.diagram.commitTransaction('add essential feature');
        }
    }, new go.Binding('visible', 'essentialFeatures', (essentialFeatures) => !essentialFeatures?.length), $(go.Shape, {
        margin: new go.Margin(-2, 1, 1, -3),
        fill: '#1DAFED',
        strokeWidth: 0,
        desiredSize: new go.Size(20, 20),
        geometry: go.Geometry.parse(svgIcons.plusOutline, true)
    }))));
};
export const TEXT_NODE_PANEL = function () {
    return $(go.Panel, 'Auto', { name: 'TEXT_NODE_PANEL', minSize: new go.Size(100, 30) }, new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(go.Size.stringify), $(go.Shape, 'Rectangle', {
        fill: 'white',
        strokeWidth: 0,
        portId: '',
        fromLinkable: true,
        toLinkable: true,
        cursor: 'pointer',
        name: 'SHAPE'
    }, new go.Binding('figure'), new go.Binding('fill', 'color')), $(go.TextBlock, {
        stroke: 'white',
        margin: 0,
        editable: true,
        font: '12px IBM Plex Sans, sans-serif',
        isMultiline: true,
        cursor: 'move',
        name: 'TEXT'
    }, new go.Binding('text').makeTwoWay()));
};
export const IMAGE_NODE_PANEL = function () {
    return $(go.Panel, 'Auto', {}, $(go.Picture, {
        imageStretch: go.GraphObject.Uniform
    }, new go.Binding('source')));
};
export const DIRECTED_LINK = function () {
    const colorByTheme = vtf.framework.theme.isDark ? 'white' : 'black';
    return $(go.Link, {
        reshapable: true,
        resegmentable: true,
        relinkableFrom: true,
        relinkableTo: true,
        routing: go.Link.AvoidsNodes,
        adjusting: go.Link.End,
        curve: go.Link.JumpOver,
        toShortLength: 4,
        corner: 30
    }, new go.Binding('points').makeTwoWay(), 
    // remember the Link.routing too
    new go.Binding('routing', 'routing', go.Binding.parseEnum(go.Link, go.Link.AvoidsNodes)).makeTwoWay(go.Binding.toString), $(go.Shape, {
        isPanelMain: true,
        strokeWidth: 2,
        stroke: colorByTheme,
        name: 'SHAPE'
    }), // the link path shape
    $(go.Shape, {
        toArrow: 'Standard',
        fill: colorByTheme,
        stroke: colorByTheme
    }), // the arrowhead
    $(go.Panel, 'Auto', {
        segmentOffset: new go.Point(0, -10),
        segmentOrientation: go.Link.OrientUpright
    }, $(go.TextBlock, '', // the label text
    {
        stroke: colorByTheme,
        textAlign: 'center',
        font: '12px IBM Plex Sans, sans-serif',
        margin: 4,
        editable: true,
        name: 'TEXT'
    }, 
    // editing the text automatically updates the model data
    new go.Binding('text').makeTwoWay())));
};
export const UNDIRECTED_LINK = function () {
    const colorByTheme = vtf.framework.theme.isDark ? 'white' : 'black';
    return $(go.Link, {
        reshapable: true,
        resegmentable: true,
        relinkableFrom: true,
        relinkableTo: true,
        routing: go.Link.AvoidsNodes,
        adjusting: go.Link.End,
        curve: go.Link.JumpOver,
        toShortLength: 4,
        corner: 30
    }, new go.Binding('points').makeTwoWay(), 
    // remember the Link.routing too
    new go.Binding('routing', 'routing', go.Binding.parseEnum(go.Link, go.Link.AvoidsNodes)).makeTwoWay(go.Binding.toString), $(go.Shape, {
        isPanelMain: true,
        strokeWidth: 2,
        stroke: colorByTheme,
        name: 'SHAPE'
    }), // the link path shape
    $(go.Panel, 'Auto', {
        segmentOffset: new go.Point(0, -10),
        segmentOrientation: go.Link.OrientUpright
    }, $(go.TextBlock, '', // the label text
    {
        stroke: colorByTheme,
        textAlign: 'center',
        font: '12px IBM Plex Sans, sans-serif',
        margin: 4,
        editable: true,
        name: 'TEXT'
    }, 
    // editing the text automatically updates the model data
    new go.Binding('text').makeTwoWay())));
};
